<template>
  <div style="min-height:30dvh;">
      <vue-element-loading
        :active="loading"
        text="دریافت اخرین دستورالعمل متخصص..."
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
    <div class="tipsBox" v-if="tipsInfo != null && !loading">
      <h5 class="advicerSubForm-title">
        آخرین دستورالعمل ثبت شده توسط {{ advicerName }}
      </h5>
      <h4 class="tipsTitle">{{ tipsInfo.title }}</h4>
      <p class="tipsDesc">
        {{ tipsInfo.desc }}
      </p>
      <div class="tipsMore">
        <span style="cursor: pointer">
          <i class="fa fa-commenting-o" aria-hidden="true"></i>
          {{ tipsInfo.replyCount }}
        </span>
        <span style="margin-left: 2em">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          {{ tipsInfo.dateTime }}
        </span>
        <a
          v-for="(fitem, findex) of tipsInfo.files"
          :key="findex"
          :href="downloadLink(fitem)"
          target="_blank"
        ><i class="fa fa-download" aria-hidden="true"></i
        ></a>
      </div>
    </div>
    <no-data v-else class="pt-md-5" :show="!loading" message="متخصص دستورالعمل ای ثبت نکرده است"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../../constants/config";
import NoData from "../../../components/no-data.vue";

export default {
  components: {NoData},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      tipsInfo: {
        title: "",
        desc: "",
        dateTime: "",
        replyCount: 0,
        files: [],
      },
    };
  },
  props: ["advicerId", "advicerName"],
  watch: {
    advicerId: function () {
      this.GetTips();
    },
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetLastTips"]),

    async GetTips() {
      if (this.advicerId) {
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.GetLastTips({
          userId: this.advicerId,
          userType: "Advicer",
        });
        if (result.data) {
          this.tipsInfo = result.data;
        } else {
          this.tipsInfo = null;
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      }
    },
    downloadLink(fileName) {
      return `${apiUrlRoot}/TipsFile/${fileName}`;
    },
  },
  mounted() {
    this.GetTips();
  },
  created() {
  },
};
</script>

<style scoped>
.advicerSubForm-title {
  float: right;
  width: 100%;
  text-align: right;
  padding: 8px 5px 7px 0;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}

.tipsBox {
  height: 37vh;
  width: 100%;
}

.tipsTitle {
  float: right;
  text-align: right;
  font-size: 1rem;
  margin: 0 6% 0 0;
  font-weight: bold;
  color: #088f35;
}

.tipsDesc {
  float: right;
  width: 98%;
  height: 29vh;
  padding: 10px;
  color: #a1a1a1;
  overflow-y: auto;
  background-color: whitesmoke;
  margin: 1%;
}

.tipsMore {
  float: right;
  width: 100%;
}

.tipsMore a {
  float: right;
  text-align: center;
  height: 3vh;
  margin: 1% 2% 0 0;
  padding-top: 4px;
  color: #2196f3;
}

.tipsMore span {
  float: right;
  margin: 1% 2% 0 0;
  text-align: center;
  padding-top: 4px;
  color: #4a4a4a;
}

.tipsMore span i {
  float: right;
  margin-left: 5px;
  color: #f80;
  font-size: 16px;
}
</style>
