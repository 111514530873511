<template>
  <div class="addNewAdvicerBox">
    <div v-if="advicerId > 0" class="addNewAdvicer main-card">
      <h5>
        جهت افزودن متخصص جدید کد متخصص ای را وارد کرده و جستجوی کد را کلیک کنید
      </h5>
      <CInput
        @keyup.enter="GetWithCode()"
        type="text"
        v-model="identityCode"
        placeholder="کد متخصص را اینحا وارد کنید"
      />
      <CButton color="primary" @click="GetWithCode()">
        جستجوی کد <i class="fa fa-search" aria-hidden="true"></i>
      </CButton>
      <div class="advicerDetailInfo">
        <div class="advicerDetailInfoBox-info">
          <h4>نام و نام خانوادگی</h4>
          <h5>{{ advicerItem.fullName }}</h5>
          <h4>تلفن همراه</h4>
          <h5>{{ advicerItem.phone }}</h5>
          <h4>استان(شهرستان)</h4>
          <h5>{{ advicerItem.stateCity }}</h5>
          <h4>تاریخ تولد</h4>
          <h5>{{ advicerItem.birthDate }}</h5>
          <h4>تحصیلات</h4>
          <h5>{{ advicerItem.education }}</h5>
          <h4>تلفن ثابت</h4>
          <h5>{{ advicerItem.tel }}</h5>
          <p>
            <i>آدرس</i>
            {{
              advicerItem.address == ""
                ? "آدرس متخصص ثبت نشده است"
                : advicerItem.address
            }}
          </p>
        </div>
        <div v-if="advicerItem.text">
          <div class="advicerDetailInfoBox-aboutmy">
            <i>توضیحات متخصص</i>
            <p>
              {{ advicerItem.text }}
            </p>
          </div>
          <div class="advicerDetailInfoBox-expertise">
            <span>
              <i>تخصص های متخصص</i>
              <h6 v-for="(ex, index) of advicerItem.expertise" :key="index">
                {{ ex.item1 }}
              </h6>
            </span>
          </div>
        </div>
        <no-data v-else class="pt-md-5" :show="!loading" message="متخصص پروفایل تخصصی خود را تکمیل نکرده است"/>
        <CButton color="primary" @click="RegisterAdvicer()" class="register-btn">
          تایید و شروع همکاری
        </CButton>
      </div>
    </div>
    <div v-else class="search-box">
      <div class="y-center-g-10 justify-content-center w-100">
        <CInput
          class="mb-0 col p-0"
          @keyup.enter="GetWithCode()"
          type="text"
          v-model="identityCode"
          placeholder="برای افزودن متخصص جدید کد آن را در اینجا وارد کنید"
        />
        <CButton color="primary" @click="GetWithCode()">
          <i class="fa fa-search" aria-hidden="true"></i>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NoData from "../../../components/no-data.vue";
export default {
  components: {NoData},
  data() {
    return {
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      identityCode: "",
      advicerItem: null,
      advicerId: 0,
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", [
      "FarmerAdvicerGetInfoWithCode",
      "FarmerAdvicerRegister",
    ]),

    async GetWithCode() {
      if (this.identityCode) {
        this.advicerItem = null;
        this.advicerId = 0;
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.FarmerAdvicerGetInfoWithCode({
          code: this.identityCode,
        });
        if (result.succeeded) {
          this.advicerItem = result.data;
          if (!this.advicerItem.expertise) {
            this.advicerItem.expertise = [];
            this.advicerItem.expertise.push({
              item1: "متخصص تخصص های خود را مشخص نکرده است",
            });
          }
          this.advicerId = result.data.advicerId;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `کد متخصصی را وارد کنید`,
          type: "error",
        });
      }
    },
    async RegisterAdvicer() {
      if (this.advicerId > 0) {
        this.loading = true;
        this.loadingText = "در حال دریافت اطلاعات...";
        let result = await this.FarmerAdvicerRegister({
          advicerId: this.advicerId,
        });
        if (result.succeeded) {
          this.$emit("realodList");
          this.$notify({
            title: "پیام سیستم",
            text: `متخصص ${this.advicerItem.fullName} به متخصصان شما افزوده شد`,
            type: "success",
          });
          this.advicerId = 0;
          this.advicerItem = {};
          this.identityCode = null;
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message}`,
            type: "info",
          });
        }
        this.loading = false;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `کد متخصصی را وارد کنید`,
          type: "error",
        });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped> 
.addNewAdvicer > h5 {
  float: right;
  text-align: right;
  padding: 8px 5px 7px 0px;
  font-size: 1em;
  background: linear-gradient(272deg, #c7f5c9, transparent);
  color: darkgreen;
}
.addNewAdvicer > input {
  float: right;
  width: 11vw;
  border: none;
  border-bottom: 1px solid;
  background-color: #ffffff;
  margin: 5px 1em;
  height: 2.3vh;
  text-align: center;
  outline: none;
}
.addNewAdvicer > button > i {
  float: right;
  margin: 2px;
}
.advicerDetailInfo {
  float: right;
  height: 37vh;
  width: 49vw;
}
.advicerDetailInfoBox-info {
  float: right;
  width: 18vw;
  height: 37vh;
}
.advicerDetailInfoBox-info h4 {
  float: right;
  font-size: 1em;
  clear: right;
  margin-top: 1em;
  width: 7vw;
  padding-right: 0.4em;
  border-bottom: 1px dashed #737373;
  margin-right: 0.4em;
}
.advicerDetailInfoBox-info h5 {
  float: right;
  font-size: 1em;
  margin-top: 1em;
  color: darkgreen;
  text-align: right;
  border-bottom: 1px solid #737373;
  padding: 0px 4px;
  width: 58%;
  height: 2vh;
  font-weight: bold;
}
.advicerDetailInfoBox-info p {
  float: right;
  padding: 1em;
  height: fit-content;
  width: 100%;
  clear: both;
  color: darkgreen;
  font-weight: bold;
}
.advicerDetailInfoBox-info p i {
  float: right;
  font-size: 1em;
  font-family: "Dirooz";
  border-bottom: 1px dashed #737373;
  font-style: normal;
  width: 6.1vw;
  margin-left: 10px;
  color: #434343;
  font-weight: normal;
}
.advicerDetailInfoBox-aboutmy {
  float: right;
  width: 15vw;
  height: 37vh;
  margin-left: 1em;
}
.advicerDetailInfoBox-aboutmy p {
  float: right;
  padding: 0em 0.5em 0.5em 0.5em;
  height: 32vh;
  width: 100%;
  clear: both;
  background-color: whitesmoke;
  overflow-y: auto;
  overflow-x: hidden;
  color: #f80;
}
.advicerDetailInfoBox-aboutmy i {
  float: right;
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid #3d3d3d;
  width: 100%;
  text-align: center;
  color: #3d3d3d;
  font-style: normal;
  margin-bottom: 1em;
}
.advicerDetailInfoBox-expertise {
  float: right;
  width: 14vw;
  height: 27vh;
}
.advicerDetailInfoBox-expertise span {
  float: right;
  width: 100%;
  height: 27vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.advicerDetailInfoBox-expertise span i {
  float: right;
  width: 100%;
  text-align: center;
  font-family: "Dirooz";
  font-style: normal;
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid;
}
.advicerDetailInfoBox-expertise span h6 {
  float: right;
  width: 96%;
  margin: 0.4em;
  background-color: #ecffd0;
  padding: 0.4em;
  color: #000;
}
.register-btn {
  float: left;
  background-color: darkgreen;
  border: 2px solid darkgreen;
  width: 23%;
  margin: 1em;
  padding: 1em 0em;
  color: #fff;
  font-size: 1.2em;
}
.register-btn:hover {
  background-color: #fff;
  color: darkgreen;
}

.not-expertise {
  width: 57%;
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
  font-size: 1.4em;
  color: red;
  float: right;
  background-color: #ededed;
  border-radius: 15px;
  margin-right: 4%;
}
</style>
