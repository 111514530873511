<script>
export default {
  name: "generalFarmCard",
    props: {
      item: {
        image: {

        },
        productImage: {

        },
        product: {

        },
        districtVilage: {

        },
        stateCity: {

        }
      },
    },
    components: {},
    data() {
      return {

      };
    },
    computed: {},
    methods: {},
    mounted() {},
    created() {}
  };
</script>

<template>
  <div class="base-card position-relative vertical-g-5">
    <img class="farmImg" :src="item.image" alt=""/>
    <ul class="vertical-g-10 d-flex flex-column justify-content-evenly">
      <li>نام زمین: {{ item.title }}</li>
      <li class="y-center-g-5">
        <span>{{ item.product }}</span>
        <img
          class="productImg"
          :src="item.productImage"
          alt=""
        />
      </li>
      <li class="small y-center-g-5">
        <CIcon name="cilMap"/>
        <span>آدرس: {{ item.stateCity }} - {{ item.districtVilage }}</span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<style scoped>

.farmImg {
  width: 100%;
  flex-grow: 1;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  object-fit: cover;
}
.productImg {
  width: 30px;
  height: 30px;
}

ul {
  list-style: none;
  padding: 15px;
  margin: 0;
}


</style>
