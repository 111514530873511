<template>
  <div class="fixBox">
    <main-body-header title="لیست متخصصان من">
      <CRow align-horizontal="end">
        <CCol md="8" col="auto"></CCol>
        <CCol col='col'>
          <advicerNew
            @realodList="GetAll()"
            :advicerId="advicerId"
            :advicerName="advicerName"
          ></advicerNew>
        </CCol>
      </CRow>
    </main-body-header>

    <div class="allAdvicersList body-wrapper">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div class="advicerInfoBox" v-if="advicerList.length > 0">
        <list-with-ads ads-count="2" :loading="loading">
          <div
            class="main-card advicerInfo position-relative"
            v-for="(item, index) of advicerList"
            :key="index"
          >
            <font-awesome-icon icon="check" class="position-absolute left-0 top-0 m-3 text-success"
                               v-if="advicerId === item.advicerId"/>
            <KeyValue :json="{
                    'نام و نام خانوادگی': item.fullName,
                    'شماره تلفن': item.phone,
                    'استان (شهرستان)': item.stateCity,
                    'تاریخ تولد': item.birthDate,
                    'تعداد زمین ها': `${item.farmCount} عدد زمین`,
                    'تحصیلات': item.educatuin
                  }"/>
            <div class="d-grid grid-c-2 gap-10 mt-4">
              <CButton
                color="info"
                @click="GetSubForm(item.advicerId, item.fullName, 'advicerDetail','پروفایل متخصص')"
              >
                پروفایل تخصصی
              </CButton>
              <CButton
                color="info"
                @click="GetSubForm(item.advicerId, item.fullName, 'advicerTips','آخرین دستورالعمل متخصص')"
              >
                آخرین دستورالعمل
              </CButton>
              <CButton
                color="info"
                @click="GetSubForm(item.advicerId, item.fullName, 'advicerFarms','لیست زمین های متخصص')"
              >
                لیست زمین ها
              </CButton>
              <CButton
                color="danger"
                @click="DeleteAdvicer(item.advicerId, item.fullName)"
              >
                اتمام همکاری
              </CButton>
            </div>
          </div>
        </list-with-ads>
      </div>
      <no-data v-else show message="هیچ متخصصی ثبت نشده است"/>
    </div>
    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      modal-style="max-width: 50dvw !important; height:60dvh;"
    >
      <component
        v-bind:is="subForm"
        :advicerId="advicerId"
        :advicerName="advicerName"
      />
    </VueModal>
  </div>
</template>

<script>
import advicerFarms from "./advicerFarms";
import advicerDetail from "./advicerDetail";
import advicerTips from "./advicerTips";
import advicerNew from "./advicerNew";
import {mapActions} from "vuex";
import BodyWithHeader from "../../../components/bodyWithHeader.vue";
import KeyValue from "../../../components/keyValue.vue";
import NoData from "../../../components/no-data.vue";
import ListWithAds from "../../../components/list-with-ads.vue";
import MainBodyHeader from "../../../components/mainHeader.vue";

export default {
  components: {
    MainBodyHeader,
    ListWithAds,
    NoData,
    KeyValue,
    BodyWithHeader,
    advicerFarms,
    advicerDetail,
    advicerTips,
    advicerNew,
  },
  data() {
    return {
      advicerList: [],
      modelShowState: false,
      modalTitle: "",
      subForm: null,
      loading: false,
      advicerId: 0,
      advicerName: "",
      loadingText: "در حال دریافت اطلاعات ...",
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", [
      "GetAllFarmerAdvicer",
      "FarmerAdvicerDelete",
    ]),

    async GetAll() {
      this.advicerList = [];
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.GetAllFarmerAdvicer({searchName: ""});
      if (result.data) {
        this.advicerList = result.data;
      }
      this.loading = false;
    },
    GetSubForm(advicerId, advicerName, subForm, title = "") {
      this.advicerId = advicerId;
      this.advicerName = advicerName;
      this.subForm = subForm;
      this.modalTitle = title;
      this.modelShowState = true;

    },
    async DeleteAdvicer(advicerId, advicerName) {
      this.$confirm({
        message: `با متخصص ${advicerName} قطع همکاری میکنید؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            this.loadingText = "در حال حذف متخصص...";
            let result = await this.FarmerAdvicerDelete({
              advicerId: advicerId,
            });
            if (result.succeeded) {
              this.$notify({
                title: "پیام سیستم",
                text: `متخصص از لیست همکاران حذف شد`,
                type: "success",
              });
              this.GetDetail(0);
              this.GetAll();
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `${result.message}`,
                type: "error",
              });
            }
            this.loading = false;
          }
        },
      });
    },
  },
  mounted() {
    this.GetAll();
  },
  created() {
  },
};
</script>

<style scoped>
.allAdvicersList {
  height: 86vh;
}

.actionBox {
  height: 86vh;
}

.advicerInfoBox {
  overflow-y: auto;
  overflow-x: hidden;
}

.advicerInfo h4 {
  float: right;
  width: 7vw;
  clear: right;
  font-size: 1em;
  margin: 0.3em 0.2em;
  text-align: left;
  padding-left: 1em;
  border-bottom: 1px dashed #bbb;
  padding-bottom: 0.13em;
}

.advicerInfo h5 {
  font-size: 1em;
  margin: auto;
  text-align: right;
  color: #019934;
}
 
</style>
